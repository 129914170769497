import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const MobileLinks = loadable(() => import('/src/components/Basic/MobileLinks'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))

const MobileLinksPage = ({ post }) => {
    return (
        <LayoutInner removeHeader={true} removeFooter={true}>
            <Seo post={post} />

            <main>
                <article itemScope itemType="http://schema.org/Article">
                    <MobileLinks post={post} />
                </article>
            </main>
        </LayoutInner>
    )
}

MobileLinksPage.propTypes = {
    /**
     * Post object
     */
    post: PropTypes.object.isRequired
}

export default MobileLinksPage
